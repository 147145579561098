<ng-container *transloco="let t">
  <div class="wrapper">
    <!-- status bar background on iOS -->
    @if (app.isIOS) {
      <div class="status-bar sticky top-0 bg-white"></div>
    }
    <div
      class="content"
      [class.dashboard-layout]="layout === Layouts.Dashboard"
    >
      @if (resultsListContext) {
        <ffb-results-page-select [context]="resultsListContext">
        </ffb-results-page-select>
      }
      <ng-content></ng-content>
    </div>
    <div class="tabs sticky bottom-0 bg-white">
      <nav tuiTabBar>
        <a
          icon="@tui.house"
          routerLink="/user/dashboard"
          routerLinkActive
          tuiTabBarItem
        >
          {{ t('nav.home') }}
        </a>
        <a
          icon="/assets/icon-set/cup.svg"
          [routerLink]="linkToResults"
          routerLinkActive
          tuiTabBarItem
        >
          {{ t('nav.results') }}
        </a>
        <a
          icon="/assets/icon-set/registrations.svg"
          routerLink="/user/registrations"
          routerLinkActive
          tuiTabBarItem
        >
          {{ t('nav.registrations') }}
        </a>
        <a icon="@tui.menu" routerLink="/menu" routerLinkActive tuiTabBarItem>
          {{ t('nav.menu') }}
        </a>
      </nav>
    </div>
  </div>

  @if (loadingPersonError) {
    <ffb-error-overlay [error]="t('errors.unableToLoad')">
      <button
        tuiButton
        type="button"
        appearance="text"
        size="m"
        class="mt3"
        (click)="auth.signOut()"
      >
        <span class="underline">
          {{ t('actions.logout') }}
        </span>
      </button>
    </ffb-error-overlay>
  }
</ng-container>
