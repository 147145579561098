import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Params,
  Route,
  RouteReuseStrategy,
} from '@angular/router';
import isEqual from 'lodash.isequal';
import { skip } from 'rxjs/operators';
import { AuthenticationService } from './authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AppRouteReuseStrategy implements RouteReuseStrategy {
  private auth = inject(AuthenticationService);

  public storedRoutes = new Map<
    Route,
    { detachedTree: DetachedRouteHandle; params: Params }
  >();

  constructor() {
    // reset stored routes on sign-in/sign-out
    this.auth.firebaseUser$.pipe(skip(1)).subscribe(() => {
      this.storedRoutes.clear();
    });
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig?.data?.reuse;
  }

  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle,
  ): void {
    if (!route.routeConfig) {
      return;
    }

    this.storedRoutes.set(route.routeConfig, {
      detachedTree,
      params: route.params,
    });
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      !!route.routeConfig &&
      !!this.storedRoutes.get(route.routeConfig) &&
      isEqual(route.params, this.storedRoutes.get(route.routeConfig)?.params)
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.storedRoutes.has(route.routeConfig)) {
      return null;
    }

    return this.storedRoutes.get(route.routeConfig)?.detachedTree ?? null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    if (curr.routeConfig?.data?.reuse === false) {
      return false;
    }

    return (
      future.routeConfig === curr.routeConfig &&
      (curr.routeConfig?.data?.reuse === 'always' ||
        isEqual(future.params, curr.params))
    );
  }
}
