import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TuiIcon, TuiLink } from '@taiga-ui/core';
import { ExternalLinkDirective } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
  standalone: true,
  imports: [ExternalLinkDirective, NgClass, TuiIcon, TuiLink],
})
export class SocialNetworksComponent {
  @Input() appearance = 'text-primary';

  socialNetworks = [
    { name: 'facebook', link: 'https://www.facebook.com/FFBridge?fref=ts' },
    {
      name: 'youtube',
      link: 'https://www.youtube.com/channel/UC00_7wddcco7sjE0EfNqY5A',
    },
    { name: 'twitter', link: 'https://twitter.com/FF_Bridge' },
    { name: 'instagram', link: 'https://www.instagram.com/ff_bridge/' },
  ];
}
