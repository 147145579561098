import { Component, Input, OnInit, inject } from '@angular/core';
import {
  ResolveEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiTabBar } from '@taiga-ui/addon-mobile';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton } from '@taiga-ui/core';
import { filter, take } from 'rxjs/operators';
import { ErrorOverlayComponent } from '@lancelot-frontend/components';
import { AppService } from '../../app.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { SignedInUserService } from '../../user/user.service';
import { Layouts } from '../app.layout';
import { ResultsPageSelectComponent } from './components/results-page-select/results-page-select.component';

@Component({
  selector: 'ffb-native-platform-layout',
  templateUrl: './native-platform.layout.html',
  styleUrls: ['./native-platform.layout.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    TuiLet,
    TuiTabBar,
    TuiButton,
    ErrorOverlayComponent,
    ResultsPageSelectComponent,
  ],
})
export class NativePlatformLayout implements OnInit {
  private router = inject(Router);
  private signedInUserService = inject(SignedInUserService);
  protected app = inject(AppService);
  protected auth = inject(AuthenticationService);

  Layouts = Layouts;
  @Input() layout?: Layouts;
  loadingPersonError = false;
  resultsListContext?: 'my-results-list' | 'results-list';
  linkToResults: '/competitions/results' | '/user/results' = '/user/results';

  constructor() {
    this.router.events
      .pipe(filter((event): event is ResolveEnd => event instanceof ResolveEnd))
      .subscribe((event) => {
        let child = event.state.root?.firstChild;
        this.resultsListContext = child?.data.resultsList;
        if (this.resultsListContext) {
          this.linkToResults =
            this.resultsListContext === 'my-results-list'
              ? '/user/results'
              : '/competitions/results';
        }
        while (child?.firstChild) {
          child = child.firstChild;
          this.resultsListContext = child?.data.resultsList;
          if (this.resultsListContext) {
            this.linkToResults =
              this.resultsListContext === 'my-results-list'
                ? '/user/results'
                : '/competitions/results';
          }
        }
      });
  }

  ngOnInit() {
    this.signedInUserService.signedInPerson$
      .pipe(
        filter((person) => !!person),
        take(1),
      )
      .subscribe({
        next: () => {
          this.app.loading = false;
        },
        error: () => {
          this.app.loading = false;
          this.loadingPersonError = true;
        },
      });
  }
}
