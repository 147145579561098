import { Component, Input, OnInit } from '@angular/core';
import { TSearchResultsHelpCategory } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-help-category',
  templateUrl: './search-results-help-category.component.html',
  styleUrls: ['./search-results-help-category.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsHelpCategoryComponent implements OnInit {
  @Input() helpCategory!: TSearchResultsHelpCategory['hits'][0];

  title?: string;
  content?: string;

  ngOnInit() {
    this.title = this.helpCategory._formatted.title;
    this.content = this.helpCategory._formatted.content;
  }
}
