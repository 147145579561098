import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiDataList, TuiDropdown, TuiIcon } from '@taiga-ui/core';
import { TuiTabs } from '@taiga-ui/kit';
import { IMenu } from '@lancelot-frontend/cms';
import {
  ExternalLinkDirective,
  UrlToRouterLinkPipe,
} from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-header-main-menu-desktop-link',
  template: ` <ng-container *transloco="let t">
    @if (link.__component === 'navigation.link') {
      <!-- External link -->
      @if (link.link.startsWith('http') || link.openInNewTab === true) {
        <a
          [href]="link.link"
          [target]="link.openInNewTab !== false ? '_blank' : undefined"
        >
          {{ link.label }}
        </a>
      }
      <!-- Simple tab -->
      @if (!link.link.startsWith('http') && link.openInNewTab !== true) {
        <a
          *tuiLet="link.link | urlToRouterLink as routerLink"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="routerLink.routerLink"
          [queryParams]="routerLink.queryParams"
          [fragment]="routerLink.fragment"
          routerLinkActive=""
          (isActiveChange)="this.onRouterLinkActive($event)"
        >
          {{ link.label }}
        </a>
      }
    }
    @if (link.__component === 'navigation.submenu') {
      <!-- Tab with dropdown for sub-links -->
      <div
        tuiDropdownAlign="right"
        [tuiDropdown]="children"
        [(tuiDropdownOpen)]="expanded"
      >
        <a>
          {{ link.label }}
          <tui-icon
            icon="@tui.chevron-down"
            class="icon"
            [class.upside-down]="expanded"
          ></tui-icon>
        </a>
      </div>
    }
    <ng-template #children>
      @if (link.__component === 'navigation.submenu') {
        <tui-data-list role="menu">
          @for (child of link.links; track child.link) {
            <!-- External link -->
            @if (child.link.startsWith('http') || child.openInNewTab === true) {
              <a
                [href]="child.link"
                [target]="child.openInNewTab !== false ? '_blank' : undefined"
                role="menuitem"
                tuiOption
                (click)="onClickOnChildLink()"
              >
                {{ child.label }}
              </a>
            }
            <!-- Internal link -->
            @if (
              !child.link.startsWith('http') && child.openInNewTab !== true
            ) {
              <a
                *tuiLet="child.link | urlToRouterLink as routerLink"
                [routerLink]="routerLink.routerLink"
                [queryParams]="routerLink.queryParams"
                [fragment]="routerLink.fragment"
                role="menuitem"
                tuiOption
                (click)="onClickOnChildLink()"
              >
                {{ child.label }}
              </a>
            }
          }
        </tui-data-list>
      }
    </ng-template>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    TranslocoDirective,
    TuiLet,
    TuiTabs,
    TuiDropdown,
    TuiIcon,
    TuiDataList,
    ExternalLinkDirective,
    UrlToRouterLinkPipe,
  ],
})
export class HeaderMainMenuDesktopLinkComponent {
  @Input() link!: IMenu['attributes']['links'][number];
  @Output() linkActiveChange = new EventEmitter<boolean>();

  expanded = false;

  onClickOnChildLink() {
    setTimeout(() => {
      this.expanded = false;
    });
  }

  onRouterLinkActive(isActive: boolean) {
    this.linkActiveChange.emit(isActive);
  }
}

@Component({
  selector: 'ffb-header-main-menu-desktop',
  templateUrl: './header-main-menu-desktop.component.html',
  styleUrls: ['./header-main-menu-desktop.component.scss'],
  standalone: true,
  imports: [TuiTabs, HeaderMainMenuDesktopLinkComponent],
})
export class HeaderMainMenuDesktopComponent {
  @Input() links!: IMenu['attributes']['links'];

  activeItemIndex = NaN;

  onLinkActiveChange(index: number, isActive: boolean) {
    this.activeItemIndex = isActive ? index : NaN;
  }
}
