import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLink } from '@taiga-ui/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'ffb-faciliti-button',
  templateUrl: './faciliti-button.component.html',
  styleUrls: ['./faciliti-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiLink, SvgIconComponent],
})
export class FacilitiButtonComponent {
  @Input() appearance = 'text';
}
