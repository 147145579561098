import { Component, Input, OnInit } from '@angular/core';
import { IRichText } from '@lancelot-frontend/cms';
import { TSearchResultsPage } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsPageComponent implements OnInit {
  @Input() page!: TSearchResultsPage['hits'][0];

  title?: string;
  description?: string;
  content?: string;

  ngOnInit() {
    this.title = this.page._formatted.title;
    this.description = this.page._formatted.description;
    this.content =
      (
        this.page._formatted.content.find((component, index) =>
          this.page.content[index]?.__component === 'content.rich-text' &&
          this.page._matchesPosition.content
            ? // if any content matches the query terms, find it
              (component as IRichText).content.includes('<em>')
            : // else just display the first one
              true,
        ) as IRichText
      )?.content ?? '';
  }
}
