import { Component, inject } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import {
  ErrorOverlayComponent,
  LoadingOverlayComponent,
} from '@lancelot-frontend/components';
import { AdminService } from '../../admin/admin.service';
import { AdminLayoutService } from './admin-layout.service';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';

@Component({
  selector: 'ffb-admin-layout',
  templateUrl: './admin.layout.html',
  styleUrls: ['./admin.layout.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    NavigationComponent,
    ErrorOverlayComponent,
    LoadingOverlayComponent,
    BreadcrumbsComponent,
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'admin', multi: true }],
})
export class AdminLayout {
  protected adminService = inject(AdminService);
  protected adminLayoutService = inject(AdminLayoutService);
}
