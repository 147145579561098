import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { TuiLet } from '@taiga-ui/cdk';
import {
  TuiDataList,
  TuiDropdown,
  TuiFlagPipe,
  TuiIcon,
  TuiLink,
} from '@taiga-ui/core';
import { LangService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    TuiDropdown,
    TuiLink,
    TuiLet,
    TuiIcon,
    TuiDataList,
    TuiFlagPipe,
    UpperCasePipe,
  ],
})
export class LangSelectComponent {
  private langService = inject(LangService);

  @Input() appearance = 'text-primary';

  open = false;
  availableLangs = this.langService.getAvailableLangs();
  activeLang$ = this.langService.langChanges$;

  getCountryCodeFromLang(lang: string) {
    switch (lang) {
      case 'en': {
        return 'GB';
      }
      default: {
        return lang.toUpperCase();
      }
    }
  }

  setActiveLang(lang: string) {
    this.langService.setActiveLang(lang);
    setTimeout(() => {
      this.open = false;
    });
  }
}
