<div class="wrapper">
  <ffb-admin-layout-navigation></ffb-admin-layout-navigation>
  <div class="content">
    <ffb-admin-layout-breadcrumbs></ffb-admin-layout-breadcrumbs>
    <ng-content></ng-content>
  </div>
  <ffb-admin-layout-header></ffb-admin-layout-header>
</div>

@if (adminService.error) {
  <ffb-error-overlay></ffb-error-overlay>
}
