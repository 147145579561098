import { AfterViewInit, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButton } from '@taiga-ui/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { Layouts } from '../app.layout';

@Component({
  selector: 'ffb-empty-layout',
  templateUrl: './empty.layout.html',
  styleUrls: ['./empty.layout.scss'],
  standalone: true,
  imports: [TranslocoDirective, TuiButton],
})
export class EmptyLayout implements AfterViewInit {
  private app = inject(AppService);

  displayNavigation?: boolean;

  constructor() {
    combineLatest([
      this.app.embedded$.pipe(distinctUntilChanged()),
      this.app.layout$.pipe(distinctUntilChanged()),
      this.app.canGoBack$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([embedded, layout, canGoBack]) => {
        this.displayNavigation =
          embedded && layout === Layouts.Public && canGoBack;
      });
  }

  ngAfterViewInit() {
    this.app.loading = false;
  }

  back() {
    this.app.back();
  }
}
