import { Component } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

@Component({
  selector: 'ffb-public-layout',
  templateUrl: './public.layout.html',
  styleUrls: ['./public.layout.scss'],
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
})
export class PublicLayout {}
