@if (adminLayoutService.breadcrumbs.length) {
  <tui-breadcrumbs>
    @for (breadcrumb of adminLayoutService.breadcrumbs; track breadcrumb.id) {
      <a
        *tuiItem
        tuiLink
        [routerLink]="breadcrumb.routerLink"
        [tuiSkeleton]="breadcrumb.loading"
      >
        <ng-container *polymorpheusOutlet="breadcrumb.label as label">
          {{ label }}
        </ng-container>
      </a>
    }
  </tui-breadcrumbs>
}
