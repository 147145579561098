import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButton, TuiError } from '@taiga-ui/core';
import { TuiButtonLoading, TuiInputInline } from '@taiga-ui/kit';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { UpperFirstPipe } from '@lancelot-frontend/core';
import {
  SearchService,
  TResource,
  TSearchResultsArticle,
  TSearchResultsHelpCategory,
  TSearchResultsNewsItem,
  TSearchResultsPage,
  TSearchResultsPracticalInformation,
  TSearchResultsPressFile,
} from '../search.service';
import { SearchResultsComponent } from '../search-results/search-results.component';

@Component({
  selector: 'ffb-app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    ReactiveFormsModule,
    TranslocoDirective,
    TuiError,
    TuiInputInline,
    TuiButton,
    TuiButtonLoading,
    SearchResultsComponent,
    UpperFirstPipe,
  ],
})
export class SearchFormComponent implements AfterViewInit {
  private nativeElement = inject(ElementRef).nativeElement;
  private searchService = inject(SearchService);

  protected readonly articles$ =
    new BehaviorSubject<TSearchResultsArticle | null>(null);
  protected helpCategories$ =
    new BehaviorSubject<TSearchResultsHelpCategory | null>(null);
  protected readonly newsItems$ =
    new BehaviorSubject<TSearchResultsNewsItem | null>(null);
  protected readonly pages$ = new BehaviorSubject<TSearchResultsPage | null>(
    null,
  );
  protected practicalInformation$ =
    new BehaviorSubject<TSearchResultsPracticalInformation | null>(null);
  protected readonly pressFiles$ =
    new BehaviorSubject<TSearchResultsPressFile | null>(null);

  searchForm = new FormGroup({
    q: new FormControl('', { nonNullable: true }),
  });

  loading = false;
  error = false;
  empty = false;
  expanded: TResource | null = null;
  currentQuery?: string;

  constructor() {
    this.searchForm
      .get('q')
      ?.valueChanges.pipe(
        tap((q) => {
          this.error = false;
          this.empty = false;
          if (q.length) {
            this.loading = true;
          } else {
            this.loading = false;
            this.expanded = null;
          }
        }),
        debounceTime(1400),
        switchMap((q) => {
          this.currentQuery = q;

          if (q.length) {
            return this.searchService.search({ q }).pipe(
              catchError(() => {
                this.loading = false;
                this.empty = false;
                this.error = true;

                return of([null, null, null, null, null, null, null]);
              }),
            );
          } else {
            return of([null, null, null, null, null, null, null]);
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: ([
          articles,
          helpCategories,
          newsItems,
          pages,
          practicalInformation,
          pressFiles,
        ]) => {
          this.loading = false;
          this.articles$.next(articles);
          this.helpCategories$.next(helpCategories);
          this.newsItems$.next(newsItems);
          this.pages$.next(pages);
          this.practicalInformation$.next(practicalInformation);
          this.pressFiles$.next(pressFiles);
          this.empty = !!(
            articles &&
            !articles.nbHits &&
            helpCategories &&
            !helpCategories.nbHits &&
            newsItems &&
            !newsItems.nbHits &&
            pages &&
            !pages.nbHits &&
            practicalInformation &&
            !practicalInformation.nbHits &&
            pressFiles &&
            !pressFiles.nbHits
          );
        },
      });
  }

  ngAfterViewInit() {
    this.focus();
  }

  focus() {
    this.nativeElement.getElementsByTagName('input').item(0).focus();
  }

  clear() {
    this.searchForm.get('q')?.setValue('');
    this.focus();
  }

  toggle(resource: TResource) {
    this.expanded = this.expanded === resource ? null : resource;
  }
}
