import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLink, TuiScrollbar } from '@taiga-ui/core';
import { TuiAccordion } from '@taiga-ui/kit';
import { SvgIconComponent } from 'angular-svg-icon';
import { DisableLinkDirective } from '@lancelot-frontend/core';
import { AdminService } from '../../../../admin/admin.service';
import { UserRightsService } from '../../../../user/user-rights.service';
import { AssistanceButtonComponent } from '../../../components/assistance-button/assistance-button.component';

@Component({
  selector: 'ffb-admin-layout-navigation',
  standalone: true,
  imports: [
    AsyncPipe,
    NgStyle,
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    TuiLink,
    TuiScrollbar,
    TuiAccordion,
    SvgIconComponent,
    AssistanceButtonComponent,
    DisableLinkDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  protected adminService = inject(AdminService);
  protected userRightsService = inject(UserRightsService);
}
