import { AfterViewInit, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLink } from '@taiga-ui/core';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  ContentAdornmentDirective,
  LogoComponent,
} from '@lancelot-frontend/components';
import { AppService } from '../../app.service';
import { FooterComponent } from '../public-layout/components/footer/footer.component';

@Component({
  selector: 'ffb-auth-layout',
  templateUrl: './auth.layout.html',
  styleUrls: ['./auth.layout.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    ContentAdornmentDirective,
    RouterLink,
    LogoComponent,
    TuiLink,
    FooterComponent,
  ],
})
export class AuthLayout implements AfterViewInit {
  protected app = inject(AppService);

  loadingContent?: boolean;

  constructor() {
    this.app.loadingContent$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((loadingContent) => (this.loadingContent = loadingContent));
  }

  ngAfterViewInit() {
    this.app.loading = false;
  }
}
