import { Component, Input, OnInit } from '@angular/core';
import { INewsContent } from '@lancelot-frontend/cms';
import { TSearchResultsNewsItem } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-news-item',
  templateUrl: './search-results-news-item.component.html',
  styleUrls: ['./search-results-news-item.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsNewsItemComponent implements OnInit {
  @Input() newsItem!: TSearchResultsNewsItem['hits'][0];

  title?: string;
  content?: string;

  ngOnInit() {
    this.title = this.newsItem._formatted.title;
    this.content =
      (
        this.newsItem._formatted.content.find((component, index) =>
          this.newsItem.content[index]?.__component === 'news.news-content' &&
          this.newsItem._matchesPosition.content
            ? // if any content matches the query terms, find it
              (component as INewsContent).content.includes('<em>')
            : // else just display the first one
              true,
        ) as INewsContent
      )?.content ?? '';
  }
}
