<ng-template #router>
  <div class="router-wrapper" [@routeFadeIn]="prepareOutlet(outlet)">
    <router-outlet
      #outlet="outlet"
      [ngClass]="{
        loadingContent: loadingContent && layout !== Layouts.Dashboard
      }"
      routeReuseLifecycle
    ></router-outlet>
  </div>
  @if (loadingContent && layout !== Layouts.Dashboard) {
    <ffb-loading-overlay backgroundColor="base"></ffb-loading-overlay>
  }
  @if (error) {
    <ffb-error-overlay></ffb-error-overlay>
  }
</ng-template>

<!-- Web layout -->
@if (!app.isNativePlatform) {
  <!-- Empty layout & Embedded app -->
  @if (layout === Layouts.Empty || (embedded && layout !== Layouts.Admin)) {
    <ffb-empty-layout
      [class.embedded]="embedded"
      [class.dashboard-layout]="layout === Layouts.Dashboard"
    >
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </ffb-empty-layout>
  }
  @if (!embedded || layout === Layouts.Admin) {
    @if (layout === Layouts.Public || layout === Layouts.Dashboard) {
      <ffb-public-layout>
        <!--    Public layout -->
        @if (layout === Layouts.Public) {
          <ng-container *ngTemplateOutlet="router"></ng-container>
        }
        <!--    Dashboard layout -->
        @if (layout === Layouts.Dashboard) {
          <ffb-dashboard-layout>
            <ng-container *ngTemplateOutlet="router"></ng-container>
          </ffb-dashboard-layout>
        }
      </ffb-public-layout>
    }
    <!--  Auth layout -->
    @if (layout === Layouts.Auth) {
      <ffb-auth-layout>
        <ng-container *ngTemplateOutlet="router"></ng-container>
      </ffb-auth-layout>
    }
    <!--  Admin layout -->
    @if (layout === Layouts.Admin) {
      <ffb-admin-layout>
        <ng-container *ngTemplateOutlet="router"></ng-container>
      </ffb-admin-layout>
    }
  }
} @else {
  <!-- Native platform layout -->
  @if (embedded || layout === Layouts.Empty) {
    <!-- Empty layout & Embedded app -->
    <ffb-empty-layout
      [class.embedded]="embedded"
      [class.dashboard-layout]="layout === Layouts.Dashboard"
    >
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </ffb-empty-layout>
  } @else {
    <!--  Auth layout -->
    @if (layout === Layouts.Auth) {
      <ffb-auth-layout>
        <ng-container *ngTemplateOutlet="router"></ng-container>
      </ffb-auth-layout>
    } @else {
      <!-- Main layout (with tabs and menu) -->
      <ffb-native-platform-layout [layout]="layout">
        <ng-container *ngTemplateOutlet="router"></ng-container>
      </ffb-native-platform-layout>
    }
  }
}
