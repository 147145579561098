@for (
  socialNetwork of socialNetworks;
  track socialNetwork.name;
  let i = $index
) {
  <a
    tuiLink
    [ngClass]="{ ml2: i > 0 }"
    [appearance]="appearance"
    [href]="socialNetwork.link"
    target="_blank"
  >
    <tui-icon icon="/assets/icon-set/{{ socialNetwork.name }}.svg"></tui-icon>
  </a>
}
