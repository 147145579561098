import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import {
  TRANSLOCO_SCOPE,
  TranslocoDirective,
  TranslocoService,
} from '@ngneat/transloco';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiDialogService, TuiLink } from '@taiga-ui/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { map, startWith } from 'rxjs/operators';
import { FooterService } from '@lancelot-frontend/cms';
import { LogoComponent } from '@lancelot-frontend/components';
import {
  BreakpointObserver,
  ExternalLinkDirective,
  UrlToRouterLinkPipe,
} from '@lancelot-frontend/core';
import { CobrowseIO } from '@lancelot-frontend/plugins';
import { AssistanceButtonComponent } from '../../../components/assistance-button/assistance-button.component';
import { LegalNoticeLinksComponent } from '../../../components/legal-notice-links/legal-notice-links.component';
import { SocialNetworksComponent } from '../../../components/social-networks/social-networks.component';

@Component({
  selector: 'ffb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'footer', multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgStyle,
    RouterLink,
    TranslocoDirective,
    TuiLet,
    TuiLink,
    SvgIconComponent,
    LogoComponent,
    AssistanceButtonComponent,
    SocialNetworksComponent,
    LegalNoticeLinksComponent,
    ExternalLinkDirective,
    UrlToRouterLinkPipe,
  ],
})
export class FooterComponent {
  readonly destroyRef = inject(DestroyRef);
  private translocoService = inject(TranslocoService);
  private readonly dialogService = inject(TuiDialogService);
  private footerService = inject(FooterService);
  readonly breakpointObserver = inject(BreakpointObserver);

  blocks$ = this.footerService.footer$.pipe(
    map(
      ({
        data: {
          attributes: { block1, block2, block3, block4 },
        },
      }) => [block1, block2, block3, block4],
    ),
    startWith([]),
    takeUntilDestroyed(this.destroyRef),
  );

  generateSupportCode() {
    CobrowseIO.createSessionCode().then(({ code }) => {
      this.dialogService
        .open(
          this.translocoService.translate('footer.supportCodeDialog.content', {
            code,
          }),
          {
            label: this.translocoService.translate(
              'footer.supportCodeDialog.title',
            ),
          },
        )
        .subscribe();
    });
  }
}
