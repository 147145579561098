import { Injectable, inject } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { TranslocoService } from '@ngneat/transloco';
import { TuiAlertService } from '@taiga-ui/core';
import { BehaviorSubject, from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IEntity, UsersService } from '@lancelot-frontend/api';
import { ADMIN_PREVIOUS_ENTITY_PREF_KEY } from '../app.constants';
import { AppService } from '../app.service';
import { AppRouteReuseStrategy } from '../app-route-reuse-strategy';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private app = inject(AppService);
  private translocoService = inject(TranslocoService);
  private routeReuseStrategy = inject(AppRouteReuseStrategy);
  private alertService = inject(TuiAlertService);
  private usersService = inject(UsersService);

  error = false;

  private readonly _entities$ = new BehaviorSubject<IEntity[] | undefined>(
    undefined,
  );

  get entities$() {
    return this._entities$.asObservable();
  }

  get entities() {
    return this._entities$.getValue();
  }

  private readonly _currentEntity$ = new BehaviorSubject<IEntity | undefined>(
    undefined,
  );

  get currentEntity$() {
    return this._currentEntity$.asObservable();
  }

  set currentEntity(entity) {
    this._currentEntity$.next(entity);
    if (entity) {
      Preferences.set({
        key: ADMIN_PREVIOUS_ENTITY_PREF_KEY,
        value: JSON.stringify(entity),
      });
    } else {
      Preferences.remove({
        key: ADMIN_PREVIOUS_ENTITY_PREF_KEY,
      });
    }
    this.routeReuseStrategy.storedRoutes.clear();
  }

  get currentEntity() {
    return this._currentEntity$.getValue();
  }

  get previousEntity$() {
    return from(
      Preferences.get({
        key: ADMIN_PREVIOUS_ENTITY_PREF_KEY,
      }),
    ).pipe(
      map(({ value }) =>
        typeof value === 'string' ? JSON.parse(value) : value,
      ),
    );
  }

  constructor() {
    this.usersService.getSignedInUserManageableEntities().subscribe({
      next: (entities) => {
        this._entities$.next(entities);
        this.app.loading = false;
      },
      error: () => {
        this.app.loading = false;
        this.error = true;
      },
    });
  }

  updateCurrentEntity(data: Partial<IEntity>) {
    const currentEntity = this._currentEntity$.getValue();
    if (currentEntity) {
      this._currentEntity$.next({
        ...currentEntity,
        ...data,
      });
      const entities = this._entities$.getValue();
      if (entities) {
        const entityToUpdateIndex = entities.findIndex(
          ({ ffbCode }) => ffbCode === currentEntity.ffbCode,
        );
        if (entityToUpdateIndex > -1) {
          entities[entityToUpdateIndex] = {
            ...entities[entityToUpdateIndex],
            ...data,
          };
        }
        this._entities$.next(entities);
      }
    }
  }

  deleteCurrentEntity() {
    const currentEntity = this._currentEntity$.getValue();
    if (currentEntity) {
      const entities = this._entities$.getValue();
      if (entities) {
        this._entities$.next(
          entities.filter(({ ffbCode }) => ffbCode !== currentEntity.ffbCode),
        );
      }
    }
  }

  addEntity({ ffbCode, id, label, labelArticle, type }: IEntity) {
    const entities = this._entities$.getValue() || [];
    this._entities$.next(
      entities.concat({ ffbCode, id, label, labelArticle, type }),
    );
  }

  canAccessEntity$(entityFFBCode?: IEntity['ffbCode']) {
    return this._entities$
      .pipe(filter((entities): entities is IEntity[] => !!entities))
      .pipe(
        map(
          (entities) =>
            !!entities.find(({ ffbCode }) => ffbCode === entityFFBCode),
        ),
      );
  }

  alert = (
    action: 'create' | 'delete' | 'sync' | 'update',
    status: string,
    message = '',
  ) => {
    if (!message) {
      if (status === 'error') {
        message = this.translocoService.translate('errors.unknown');
      } else {
        switch (action) {
          case 'create': {
            message = this.translocoService.translate(
              'notifications.createSuccess',
            );
            break;
          }
          case 'update': {
            message = this.translocoService.translate(
              'notifications.changeSaved',
            );
            break;
          }
          case 'delete': {
            message = this.translocoService.translate(
              'notifications.deleteSuccess',
            );
            break;
          }
          case 'sync': {
            message = this.translocoService.translate(
              'notifications.syncSuccess',
            );
            break;
          }
        }
      }
    }
    return this.alertService.open(message, { appearance: status });
  };
}
