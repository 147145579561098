import { AsyncPipe } from '@angular/common';
import { Component, Input, OnChanges, inject } from '@angular/core';
import { TuiLet } from '@taiga-ui/cdk';
import {
  TuiFallbackSrcPipe,
  TuiInitialsPipe,
  TuiSizeL,
  TuiSizeS,
} from '@taiga-ui/core';
import { TuiAvatar, TuiSkeleton } from '@taiga-ui/kit';
import { IMe, IPerson } from '@lancelot-frontend/api';
import { PersonInformationPipe } from '@lancelot-frontend/components';
import { MediaService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    TuiSkeleton,
    TuiLet,
    TuiAvatar,
    TuiFallbackSrcPipe,
    TuiInitialsPipe,
    PersonInformationPipe,
  ],
})
export class UserAvatarComponent implements OnChanges {
  private mediaService = inject(MediaService);

  @Input() person?: IMe['person'] | IPerson | null;
  @Input() size?: 'l2' | 'm2' | TuiSizeL | TuiSizeS = 'l2';

  avatarUrl: null | string = null;

  ngOnChanges() {
    if (this.person) {
      if (this.person.avatarUrl) {
        this.avatarUrl = this.mediaService.getAvatarUrl(this.person.avatarUrl);
      } else if (
        !(this.person.firstName ?? '')
          .concat(this.person.lastName ?? '')
          .replace(/ /g, '')
      ) {
        this.avatarUrl = '@tui.user';
      } else {
        this.avatarUrl = null;
      }
    }
  }
}
