<div class="wrapper" attr.data-test-id="search-result-{{ resource }}">
  <div class="flex flex-row justify-start items-baseline py2">
    @if (firstBlock?.[1]; as innerHTML) {
      <div class="block flex-100 pr2">
        <p
          class="ellipsis"
          [innerHTML]="innerHTML"
          attr.data-test-id="search-result-{{ resource }}-{{ firstBlock?.[0] }}"
        ></p>
      </div>
    }
    @if (secondBlock?.[1]; as innerHTML) {
      <div class="block flex-100 pr2">
        <p
          class="ellipsis"
          [innerHTML]="innerHTML"
          attr.data-test-id="search-result-{{ resource }}-{{
            secondBlock?.[0]
          }}"
        ></p>
      </div>
    }
    @if (thirdBlock?.[1]; as innerHTML) {
      <p
        class="flex-auto"
        [innerHTML]="innerHTML"
        attr.data-test-id="search-result-{{ resource }}-{{ thirdBlock?.[0] }}"
      ></p>
    }
  </div>
</div>
