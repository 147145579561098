import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IArticle,
  IArticleCategory,
  IHelpCategory,
  INewsItem,
  IPage,
  IPracticalInformation,
  IPressFile,
} from '@lancelot-frontend/cms';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppAnalyticsService } from '../app-analytics.service';

export type TResource =
  | 'article'
  | 'helpCategory'
  | 'newsItem'
  | 'page'
  | 'practicalInformation'
  | 'pressFile';

export type TSearchResults<T> = {
  hits: (T & {
    _formatted: T;
    _matchesPosition: {
      [key in keyof T]: { length: number; start: number };
    };
  })[];
  limit: number;
  nbHits: number;
  offset: number;
  processingTimeMs: number;
  query: string;
};

export type TSearchResultsArticle = TSearchResults<{
  article_categories: Omit<IArticleCategory['attributes'], 'articles'>[];
  content: IArticle['attributes']['content'];
  id: IArticle['id'];
  intro: IArticle['attributes']['intro'];
  slug: IArticle['attributes']['slug'];
  title: IArticle['attributes']['title'];
}>;

export type TSearchResultsHelpCategory = TSearchResults<{
  content: IHelpCategory['attributes']['content'];
  id: IHelpCategory['id'];
  title: IHelpCategory['attributes']['title'];
}>;

export type TSearchResultsNewsItem = TSearchResults<{
  content: INewsItem['attributes']['content'];
  id: INewsItem['id'];
  slug: INewsItem['attributes']['slug'];
  title: INewsItem['attributes']['title'];
}>;

export type TSearchResultsPage = TSearchResults<{
  content: IPage['attributes']['content'];
  description: IPage['attributes']['description'];
  id: IPage['id'];
  slug: IPage['attributes']['slug'];
  title: IPage['attributes']['title'];
}>;

export type TSearchResultsPracticalInformation = TSearchResults<{
  id: IPracticalInformation['id'];
  slug: IPracticalInformation['attributes']['slug'];
  subparts: IPracticalInformation['attributes']['subparts'];
  'subparts.content': IPracticalInformation['attributes']['subparts'][0]['content'];
  'subparts.title': IPracticalInformation['attributes']['subparts'][0]['title'];
  title: IPracticalInformation['attributes']['title'];
}>;

export type TSearchResultsPressFile = TSearchResults<{
  file: IPressFile['attributes']['file']['data']['attributes'];
  id: IPressFile['id'];
  name: IPressFile['attributes']['name'];
  type: IPressFile['attributes']['type'];
}>;

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private http = inject(HttpClient);
  private analyticsService = inject(AppAnalyticsService);
  searchUrl = inject(EnvironmentService).get('cmsBaseUrl') + '/api/search';

  // Search
  search(data: {
    q: string;
  }): Observable<
    [
      TSearchResultsArticle,
      TSearchResultsHelpCategory,
      TSearchResultsNewsItem,
      TSearchResultsPage,
      TSearchResultsPracticalInformation,
      TSearchResultsPressFile,
    ]
  > {
    return zip(
      this.http.post<TSearchResultsArticle>(`${this.searchUrl}/article`, data),
      this.http.post<TSearchResultsHelpCategory>(
        `${this.searchUrl}/help-category`,
        data,
      ),
      this.http.post<TSearchResultsNewsItem>(
        `${this.searchUrl}/news-item`,
        data,
      ),
      this.http.post<TSearchResultsPage>(`${this.searchUrl}/page`, data),
      this.http.post<TSearchResultsPracticalInformation>(
        `${this.searchUrl}/practical-information`,
        data,
      ),
      this.http.post<TSearchResultsPressFile>(
        `${this.searchUrl}/press-file`,
        data,
      ),
    ).pipe(
      tap(() => {
        this.analyticsService.logEvent('search', { search_term: data.q });
      }),
    );
  }
}
