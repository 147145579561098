<ng-container *transloco="let t">
  <div class="mt3 mx2">
    <div
      [tuiDropdown]="dropdown"
      [(tuiDropdownOpen)]="open"
      class="block full-width"
    >
      <h3 class="tui-text_h3 flex flex-row items-center justify-between gap1">
        {{
          t(context === 'results-list' ? 'nav.allResults' : 'nav.user.results')
        }}
        <tui-icon
          icon="@tui.chevron-down"
          class="transform-ease-in-out fit-text"
          [class.upside-down]="open"
        ></tui-icon>
      </h3>

      <ng-template #dropdown>
        <tui-data-list class="user-dashboard-menu" role="menu">
          <a tuiOption routerLink="/user/results" role="menuitem">
            {{ t('nav.user.results') }}
          </a>
          <a tuiOption routerLink="/competitions/results" role="menuitem">
            {{ t('nav.allResults') }}
          </a>
        </tui-data-list>
      </ng-template>
    </div>
  </div>
</ng-container>
