import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiSidebar } from '@taiga-ui/addon-mobile';
import {
  TUI_FALSE_HANDLER,
  TuiActiveZone,
  TuiLet,
  TuiSwipeService,
} from '@taiga-ui/cdk';
import {
  TuiButton,
  TuiDataList,
  TuiDropdown,
  TuiIcon,
  TuiLink,
} from '@taiga-ui/core';
import { TuiElasticContainer, TuiSkeleton } from '@taiga-ui/kit';
import { Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { PersonInformationPipe } from '@lancelot-frontend/components';
import { AdminService } from '../../../../admin/admin.service';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { UtilsService } from '../../../../common/utils.service';
import { UserAvatarComponent } from '../../../../user/components/user-avatar/user-avatar.component';
import { SignedInUserService } from '../../../../user/user.service';
import { NavigationComponent } from '../navigation/navigation.component';

@Component({
  selector: 'ffb-admin-layout-header',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    TranslocoDirective,
    TuiSkeleton,
    TuiLet,
    TuiSidebar,
    TuiActiveZone,
    TuiElasticContainer,
    TuiDataList,
    TuiDropdown,
    TuiLink,
    TuiButton,
    TuiIcon,
    NavigationComponent,
    UserAvatarComponent,
    PersonInformationPipe,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TuiSwipeService],
})
export class HeaderComponent {
  private router = inject(Router);
  private auth = inject(AuthenticationService);
  private signedInUserService = inject(SignedInUserService);
  protected adminService = inject(AdminService);
  protected utils = inject(UtilsService);

  private readonly _swipes$ = inject(TuiSwipeService);
  private readonly _stream$ = new Subject<boolean>();

  readonly open$ = merge(
    this.router.events.pipe(map(TUI_FALSE_HANDLER)),
    this._stream$,
    this._swipes$.pipe(
      filter(
        (swipe) => swipe.direction === 'left' || swipe.direction === 'right',
      ),
      map((swipe) => swipe.direction === 'right'),
    ),
  ).pipe(startWith(false), distinctUntilChanged());

  signedInPerson$ = this.signedInUserService.signedInPerson$;
  userMenuExpanded = false;
  outButtonHovered = false;

  onClick(): void {
    this._stream$.next(true);
  }

  onActiveZone(active: boolean): void {
    if (!active) {
      this._stream$.next(false);
    }
  }

  logout() {
    this.auth.signOut();
  }
}
