import { Component, Input, OnInit } from '@angular/core';
import { TSearchResultsArticle } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-article',
  templateUrl: './search-results-article.component.html',
  styleUrls: ['./search-results-article.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsArticleComponent implements OnInit {
  @Input() article!: TSearchResultsArticle['hits'][0];

  title?: string;
  content?: string;

  ngOnInit() {
    this.title = this.article._formatted.title;
    this.content =
      !this.article._matchesPosition.intro &&
      this.article._matchesPosition.content
        ? this.article._formatted.content
        : this.article._formatted.intro;
  }
}
