<tui-avatar
  *tuiLet="
    person
      | personInformation: { lastName: true, firstName: true }
      | tuiInitials as initials
  "
  [src]="avatarUrl ? (avatarUrl | tuiFallbackSrc: initials | async) : initials"
  appearance="primary"
  [tuiSkeleton]="!person"
  [size]="size"
  [round]="true"
></tui-avatar>
