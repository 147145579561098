<div [tuiDropdown]="dropdown" [(tuiDropdownOpen)]="open">
  <a tuiLink [appearance]="appearance" data-test-id="lang-select">
    <div
      class="flex flex-row items-center"
      *tuiLet="activeLang$ | async as activeLang"
    >
      <img
        class="fit-text-height"
        [alt]="activeLang"
        [src]="getCountryCodeFromLang(activeLang) | tuiFlag"
      />
      <span class="mx1">
        {{ activeLang | uppercase }}
      </span>
      <tui-icon
        icon="@tui.chevron-down"
        class="transform-ease-in-out fit-text"
        [class.upside-down]="open"
      ></tui-icon>
    </div>
  </a>

  <ng-template #dropdown>
    <tui-data-list>
      @for (lang of availableLangs; track lang) {
        <button tuiOption (click)="setActiveLang(lang)">
          <img
            class="fit-text-height"
            [alt]="lang"
            [src]="getCountryCodeFromLang(lang) | tuiFlag"
          />
          <span class="ml1">
            {{ lang | uppercase }}
          </span>
        </button>
      }
    </tui-data-list>
  </ng-template>
</div>
