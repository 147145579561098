import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TuiItem } from '@taiga-ui/cdk';
import { TuiLink } from '@taiga-ui/core';
import { TuiBreadcrumbs, TuiSkeleton } from '@taiga-ui/kit';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { AdminLayoutService } from '../../admin-layout.service';

@Component({
  selector: 'ffb-admin-layout-breadcrumbs',
  standalone: true,
  imports: [
    RouterLink,
    PolymorpheusOutlet,
    TuiSkeleton,
    TuiBreadcrumbs,
    TuiItem,
    TuiLink,
  ],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  protected adminLayoutService = inject(AdminLayoutService);
}
