import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { TuiDialogService, TuiLink } from '@taiga-ui/core';
import { CobrowseIO } from '@lancelot-frontend/plugins';

@Component({
  selector: 'ffb-assistance-button',
  templateUrl: './assistance-button.component.html',
  styleUrls: ['./assistance-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiLink],
})
export class AssistanceButtonComponent {
  private translocoService = inject(TranslocoService);
  private readonly dialogService = inject(TuiDialogService);

  @Input() icon?: string;
  @Input() appearance = 'text-primary';
  @Input() iconAlign?: 'left' | 'right' = 'left';

  generateSupportCode() {
    CobrowseIO.createSessionCode().then(({ code }) => {
      this.dialogService
        .open(
          this.translocoService.translate('actions.supportCodeDialog.content', {
            code,
          }),
          {
            label: this.translocoService.translate(
              'actions.supportCodeDialog.title',
            ),
          },
        )
        .subscribe();
    });
  }
}
