import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TranslocoDecimalPipe } from '@ngneat/transloco-locale';
import { TuiButton, TuiExpand } from '@taiga-ui/core';
import {
  AsPipe,
  ExternalLinkDirective,
  SlugifyPipe,
  UpperFirstPipe,
} from '@lancelot-frontend/core';
import {
  TResource,
  TSearchResultsArticle,
  TSearchResultsHelpCategory,
  TSearchResultsNewsItem,
  TSearchResultsPage,
  TSearchResultsPracticalInformation,
  TSearchResultsPressFile,
} from '../search.service';
import { SearchResultsArticleComponent } from './article/search-results-article.component';
import { SearchResultsHelpCategoryComponent } from './help-category/search-results-help-category.component';
import { SearchResultsNewsItemComponent } from './news-item/search-results-news-item.component';
import { SearchResultsPageComponent } from './page/search-results-page.component';
import { SearchResultsPracticalInformationComponent } from './practical-information/search-results-practical-information.component';
import { SearchResultsPressFileComponent } from './press-file/search-results-press-file.component';

@Component({
  selector: 'ffb-app-search-form-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    TuiButton,
    TuiExpand,
    RouterLink,
    SearchResultsArticleComponent,
    SearchResultsHelpCategoryComponent,
    SearchResultsNewsItemComponent,
    SearchResultsPageComponent,
    SearchResultsPracticalInformationComponent,
    ExternalLinkDirective,
    SearchResultsPressFileComponent,
    TranslocoDecimalPipe,
    SlugifyPipe,
    UpperFirstPipe,
    AsPipe,
  ],
})
export class SearchResultsComponent {
  @Input() resource!: TResource;
  @Input() results!:
    | TSearchResultsArticle
    | TSearchResultsHelpCategory
    | TSearchResultsNewsItem
    | TSearchResultsPage
    | TSearchResultsPracticalInformation
    | TSearchResultsPressFile;
  @Input() expanded = false;
  @Output() toggle = new EventEmitter<void>();

  TSearchResultsArticle!: TSearchResultsArticle;
  TSearchResultsHelpCategory!: TSearchResultsHelpCategory;
  TSearchResultsNewsItem!: TSearchResultsNewsItem;
  TSearchResultsPage!: TSearchResultsPage;
  TSearchResultsPracticalInformation!: TSearchResultsPracticalInformation;
  TSearchResultsPressFile!: TSearchResultsPressFile;

  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.toggle.emit();
  }
}
