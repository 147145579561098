<ng-container *transloco="let t">
  <a
    tuiLink
    [iconStart]="icon"
    [appearance]="appearance"
    (click)="generateSupportCode()"
  >
    {{ t('actions.generateSupportCode') }}
  </a>
</ng-container>
