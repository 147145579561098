import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  ErrorOverlayComponent,
  LoadingOverlayComponent,
} from '@lancelot-frontend/components';
import { RouteReuseLifeCycleDirective } from '@lancelot-frontend/core';
import { RouterAnimations } from '../../animations/router.animations';
import { AppService } from '../app.service';
import { AdminLayout } from './admin-layout/admin.layout';
import { AuthLayout } from './auth-layout/auth.layout';
import { DashboardLayout } from './dashboard-layout/dashboard.layout';
import { EmptyLayout } from './empty-layout/empty.layout';
import { NativePlatformLayout } from './native-platform-layout/native-platform.layout';
import { PublicLayout } from './public-layout/public.layout';

export enum Layouts {
  Public, // default layout
  Auth,
  Dashboard,
  Admin,
  Empty,
}

@Component({
  selector: 'ffb-app-layout',
  templateUrl: './app.layout.html',
  styleUrls: ['./app.layout.scss'],
  animations: [RouterAnimations.routeFadeIn],
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
    LoadingOverlayComponent,
    ErrorOverlayComponent,
    EmptyLayout,
    NgTemplateOutlet,
    PublicLayout,
    DashboardLayout,
    AuthLayout,
    NativePlatformLayout,
    AdminLayout,
    RouteReuseLifeCycleDirective,
  ],
})
export class Layout {
  protected app = inject(AppService);

  Layouts = Layouts;

  embedded?: boolean;
  layout?: Layouts;
  loadingContent?: boolean;
  error?: boolean;

  constructor() {
    this.app.embedded$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((embedded) => {
        this.embedded = embedded;
      });
    this.app.layout$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((layout) => {
        this.layout = layout;
      });
    this.app.loadingContent$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((loadingContent) => {
        this.loadingContent = loadingContent;
      });

    combineLatest([
      this.app.layout$.pipe(distinctUntilChanged()),
      this.app.apiDown$.pipe(distinctUntilChanged()),
      this.app.cmsDown$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([layout, apiDown, cmsDown]) => {
        this.error =
          (apiDown && layout !== Layouts.Admin) ||
          (cmsDown && layout === Layouts.Public);
      });
  }

  prepareOutlet(outlet: RouterOutlet) {
    if (outlet.isActivated) {
      return outlet.activatedRoute;
    }
    return {};
  }
}
