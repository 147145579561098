<ng-container *transloco="let t">
  <div class="container full-height flex flex-column bg-green-gradient">
    <div class="flex flex-row items-center justify-center p3">
      <a routerLink="/" class="ffb-logo">
        <svg-icon src="/assets/images/ffb-logo-inline.svg"></svg-icon>
      </a>
    </div>

    <nav class="flex-auto">
      <tui-scrollbar class="max-full-height">
        @if (adminService.currentEntity$ | async; as currentEntity) {
          <tui-accordion [rounded]="false">
            <!--  Home  -->
            <tui-accordion-item
              size="s"
              [showArrow]="false"
              [open]="false"
              [borders]="null"
              [routerLink]="['admin', currentEntity.ffbCode]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ t('admin.nav.home') }}
            </tui-accordion-item>

            <!--  Entity information  -->
            <tui-accordion-item
              size="s"
              [showArrow]="false"
              [open]="false"
              [borders]="null"
              [routerLink]="['admin', currentEntity.ffbCode, 'information']"
              routerLinkActive="active"
            >
              {{
                t('admin.nav.entities.entityDetail.information', {
                  entity: t(
                    'resources.' +
                      (currentEntity.type === 'generic' ||
                      currentEntity.type === 'federation'
                        ? 'organization'
                        : currentEntity.type),
                    { count: 1 }
                  ),
                  entityWithPartitiveArticle: t(
                    'resources.' +
                      (currentEntity.type === 'generic' ||
                      currentEntity.type === 'federation'
                        ? 'organization'
                        : currentEntity.type) +
                      '.withPartitiveArticle',
                    { count: 1 }
                  )
                })
              }}
            </tui-accordion-item>

            <!--  Members  -->
            <tui-accordion-item
              size="s"
              [showArrow]="false"
              [open]="false"
              [borders]="null"
              [routerLink]="['admin', currentEntity.ffbCode, 'members']"
              routerLinkActive="active"
            >
              {{ t('admin.nav.members') }}
            </tui-accordion-item>

            <!--  Users (FFB admins only)  -->
            @if (userRightsService.canAccessUsers(currentEntity) | async) {
              <tui-accordion-item
                size="s"
                [showArrow]="false"
                [open]="false"
                [borders]="null"
                [routerLink]="['admin', currentEntity.ffbCode, 'users']"
                [queryParams]="{ hasPerson: false }"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  matrixParams: 'ignored',
                  queryParams: 'ignored',
                  paths: 'subset',
                  fragment: 'ignored'
                }"
              >
                {{ t('admin.nav.users') }}
              </tui-accordion-item>
            }

            <!--  Entity creation (FFB admins only)  -->
            @if (
              userRightsService.canAccessEntitiesSettings(currentEntity) | async
            ) {
              <tui-accordion-item
                size="s"
                [borders]="null"
                [routerLink]="['admin', currentEntity.ffbCode, 'entities']"
                routerLinkActive
                #link="routerLinkActive"
                [class.active]="link.isActive"
                [open]="link.isActive"
                [disableLink]="true"
              >
                {{ t('admin.nav.entities') }}
                <ng-template tuiAccordionItemContent>
                  <div class="flex flex-column items-stretch">
                    <a
                      tuiLink
                      [routerLink]="[
                        'admin',
                        currentEntity.ffbCode,
                        'entities',
                        'create'
                      ]"
                      routerLinkActive="active"
                    >
                      {{ t('actions.create') }}
                      {{
                        t('resources.entity.withIndefiniteArticle', {
                          count: 1
                        })
                      }}
                    </a>
                  </div>
                </ng-template>
              </tui-accordion-item>
            }

            <!--  Competitions & divisions CRUD (FFB admins only)  -->
            @if (
              userRightsService.canAccessCompetitionSettings(currentEntity)
                | async
            ) {
              <tui-accordion-item
                size="s"
                [borders]="null"
                [routerLink]="['admin', currentEntity.ffbCode, 'competitions']"
                routerLinkActive
                #link="routerLinkActive"
                [class.active]="link.isActive"
                [open]="link.isActive"
                [disableLink]="true"
              >
                {{ t('admin.nav.competitions') }}
                <ng-template tuiAccordionItemContent>
                  <div class="flex flex-column items-stretch">
                    <a
                      tuiLink
                      [routerLink]="[
                        'admin',
                        currentEntity.ffbCode,
                        'competitions',
                        'competitions'
                      ]"
                      routerLinkActive="active"
                    >
                      {{ t('admin.nav.competitions.competitions') }}
                    </a>
                    <a
                      tuiLink
                      [routerLink]="[
                        'admin',
                        currentEntity.ffbCode,
                        'competitions',
                        'divisions'
                      ]"
                      routerLinkActive="active"
                    >
                      {{ t('admin.nav.competitions.divisions') }}
                    </a>
                  </div>
                </ng-template>
              </tui-accordion-item>
            }
          </tui-accordion>
        }
      </tui-scrollbar>
    </nav>

    <div class="px2 py1 center">
      <ffb-assistance-button
        appearance="text-secondary-on-accent"
        icon="@tui.circle-help"
      ></ffb-assistance-button>
    </div>
  </div>
</ng-container>
