<button
  tuiIconButton
  appearance="icon"
  iconStart="@tui.zoom-out"
  size="s"
  (click)="zoom(-0.1)"
></button>
<button
  tuiIconButton
  appearance="icon"
  iconStart="@tui.zoom-in"
  size="s"
  (click)="zoom(0.1)"
></button>
