import { Component, Input, OnInit } from '@angular/core';
import { IRichText } from '@lancelot-frontend/cms';
import { TSearchResultsPracticalInformation } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-practical-information',
  templateUrl: './search-results-practical-information.component.html',
  styleUrls: ['./search-results-practical-information.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsPracticalInformationComponent implements OnInit {
  @Input() practicalInformation!: TSearchResultsPracticalInformation['hits'][0];

  title?: string;
  subpart?: IRichText;

  ngOnInit() {
    this.title = this.practicalInformation._formatted.title;
    if (
      this.practicalInformation._matchesPosition['subparts.title'] ||
      this.practicalInformation._matchesPosition['subparts.content']
    ) {
      this.subpart = this.practicalInformation._formatted.subparts.find(
        (subpart) =>
          subpart.title?.includes('<em>') || subpart.content.includes('<em>'),
      );
    }
  }
}
