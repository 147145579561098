<ng-container *transloco="let t">
  @for (link of links; track link) {
    <!-- External link -->
    @if (link.href) {
      <a
        tuiLink
        [appearance]="
          appearance === 'desktop' ? 'text-secondary-on-accent' : 'text'
        "
        [href]="link.href"
        target="_blank"
        class="nav ml2"
      >
        {{ link.label || t(link.labelTranslationKey) }}
      </a>
    }
    <!-- Simple link -->
    @if (link.routerLink && !link.children?.length) {
      <a
        tuiLink
        [appearance]="
          appearance === 'desktop' ? 'text-secondary-on-accent' : 'text'
        "
        [routerLink]="link.routerLink"
        class="nav ml2"
      >
        {{ link.label || t(link.labelTranslationKey) }}
      </a>
    }
  }
  <ffb-assistance-button
    [appearance]="
      appearance === 'desktop' ? 'text-secondary-on-accent' : 'text'
    "
  ></ffb-assistance-button>
  <!-- TODO: handle link with children -->
  <ffb-faciliti-button
    [appearance]="
      appearance === 'desktop' ? 'text-secondary-on-accent' : 'text'
    "
  ></ffb-faciliti-button>
  <ffb-lang-select
    [appearance]="
      appearance === 'desktop' ? 'text-secondary-on-accent' : 'text'
    "
  ></ffb-lang-select>
  <ffb-social-networks
    [appearance]="
      appearance === 'desktop' ? 'text-primary-on-accent' : 'text-primary'
    "
  ></ffb-social-networks>
  @if (textZoomIsAvailable) {
    <ffb-zoom-controls></ffb-zoom-controls>
  }
</ng-container>
