<ng-container *transloco="let t">
  <span
    [class]="
      appearance === 'text-secondary-on-accent'
        ? 'color-text-secondary-on-accent-1'
        : 'color-text-secondary'
    "
  >
    {{ t('footer.copyright') }}
  </span>
  |
  @if (app.isNativePlatform) {
    <a
      tuiLink
      [appearance]="appearance"
      (click)="appAnalyticsService.openAxeptioCookies()"
    >
      {{ t('nav.cookiePreferences') }}
    </a>
    |
  }
  <a tuiLink [appearance]="appearance" routerLink="/legal-notice">
    {{ t('nav.legalNotice') }}
  </a>
  |
  <a tuiLink [appearance]="appearance" routerLink="/privacy-policy">
    {{ t('nav.privacyPolicy') }}
  </a>
  |
  <a
    tuiLink
    [appearance]="appearance"
    href="https://www.clairepinot.fr/"
    target="_blank"
  >
    {{ t('footer.designCredits') }}
  </a>
  @if (app.isNativePlatform) {
    |
    <span
      [class]="
        appearance === 'text-secondary-on-accent'
          ? 'color-text-secondary-on-accent-1'
          : 'color-text-secondary'
      "
    >
      v{{ releaseName }} ({{ distName }})
    </span>
  }
</ng-container>
