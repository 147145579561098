import { Component, Input, OnInit } from '@angular/core';
import { TSearchResultsPressFile } from '../../search.service';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
  selector: 'ffb-search-results-press-file',
  templateUrl: './search-results-press-file.component.html',
  styleUrls: ['./search-results-press-file.component.scss'],
  standalone: true,
  imports: [SearchResultComponent],
})
export class SearchResultsPressFileComponent implements OnInit {
  @Input() pressFile!: TSearchResultsPressFile['hits'][0];

  type?: string;
  name?: string;

  ngOnInit() {
    this.type = this.pressFile._formatted.type;
    this.name = this.pressFile._formatted.name;
  }
}
