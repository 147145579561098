import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

const TRANSITION = '{{duration}}ms ease-in-out';
const DURATION = { params: { duration: 300 } };

export class RouterAnimations {
  static routeFadeIn = trigger('routeFadeIn', [
    transition(
      '* => *',
      [
        query(
          ':enter *',
          [style({ opacity: 0 }), animate(TRANSITION, style({ opacity: 1 }))],
          { optional: true },
        ),
      ],
      DURATION,
    ),
  ]);
}
