import { Component } from '@angular/core';
import { ContentAdornmentDirective } from '@lancelot-frontend/components';

@Component({
  selector: 'ffb-dashboard-layout',
  templateUrl: './dashboard.layout.html',
  styleUrls: ['./dashboard.layout.scss'],
  standalone: true,
  imports: [ContentAdornmentDirective],
})
export class DashboardLayout {}
