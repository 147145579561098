<ng-container *transloco="let t">
  <a
    tuiLink
    [appearance]="appearance"
    data-faciliti-popin
    [attr.aria-label]="'FACIL\'iti : ' + t('actions.adaptTheDisplay')"
  >
    <svg-icon src="/assets/faciliti/logo.svg"></svg-icon>
    {{ t('actions.adaptTheDisplay') }}
  </a>
</ng-container>
