import { Component, Input } from '@angular/core';
import { TResource } from '../../search.service';

@Component({
  selector: 'ffb-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  standalone: true,
})
export class SearchResultComponent {
  @Input() resource!: TResource;
  @Input() firstBlock?: [string, string];
  @Input() secondBlock?: [string, string];
  @Input() thirdBlock?: [string, string];
}
